import { template as template_16db6900a5114cb7a9c7640e370f5a37 } from "@ember/template-compiler";
const WelcomeHeader = template_16db6900a5114cb7a9c7640e370f5a37(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
