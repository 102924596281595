import { template as template_097cfdd3acff4cbb9ce2153d56c3632c } from "@ember/template-compiler";
const FKLabel = template_097cfdd3acff4cbb9ce2153d56c3632c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
