import { template as template_3ce020b6ebbd42d7ad9b3827c5a9372c } from "@ember/template-compiler";
const SidebarSectionMessage = template_3ce020b6ebbd42d7ad9b3827c5a9372c(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
